/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

/** disable angular mat theme warning */
.mat-theme-loaded-marker {
  display: none;
}

:root {
  --header-navbar-height: 72px;
  --sidebar-width: 270px;
  --show-choose-locale-height: 50px;
  --show-choose-locale-font-size: initial;

  @include media-breakpoint-up(sm) {
    --show-choose-locale-height: 45px;
  }

  @include media-breakpoint-down(sm) {
    --show-choose-locale-font-size: 0.9rem;
  }
}

html {
  height: -webkit-fill-available;
  position: relative;
}

body {
  min-height: 100dvh;
  min-height: -webkit-fill-available;
}

.icon-xs {
  width: 1.25rem;
  height: 1.25rem;
}

.icon-small {
  display: flex !important;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem !important;
}

.icon-medium {
  display: flex !important;
  width: 2rem !important;
  height: 2rem !important;
  font-size: 2rem !important;
}

.icon-large {
  display: flex !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
  font-size: 2.5rem !important;
}

.icon-xl {
  display: flex !important;
  width: 3rem !important;
  height: 3rem !important;
  font-size: 3rem !important;
}

.grayscale {
  filter: grayscale(1);
}

.border-radius-50 {
  border-radius: 50%;
}

.w-70 {
  width: 70% !important;
}

.w-50px {
  width: 50px;
}

.p-2-5 {
  padding: 0.75rem !important;
}

.ps-2-5 {
  padding-left: 0.75rem !important;
}

.ps-4-25 {
  padding-left: 2.25rem;
}

.pe-4-5 {
  padding-right: 2.5rem;
}

.ms-2-5 {
  margin-left: 0.75rem !important;
}

.fs-1-2 {
  font-size: 2.25rem;
}

.fs-5-6 {
  font-size: 1.125rem;
}

.fs-smaller {
  font-size: smaller;
}

.bg-opacity-15 {
  --bs-bg-opacity: 0.15;
}

.bg-opacity-95 {
  --bs-bg-opacity: 0.95;
}

@include media-breakpoint-up(sm) {
  .w-sm-50 {
    width: 50% !important;
  }
}

@include media-breakpoint-up(md) {
  .w-md-40 {
    width: 40% !important;
  }
}

@include media-breakpoint-up(lg) {
  .w-lg-70 {
    width: 70% !important;
  }
}

@include media-breakpoint-up(xl) {
  .w-xl-50 {
    width: 50% !important;
  }
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.border-bottom-dashed {
  border-bottom-style: dashed !important;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  height: auto;
}

.box-img {
  width: 100%;
  background-color: #fff;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  box-shadow: 0 8px 50px 0 rgba(0, 0, 0, .1);

  h3 {
    height: 3rem;
  }
}

.check-list {
  list-style: url("/assets/img/svg/spec-check.svg");

  li {
    font-size: 0.9375rem;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
}

.position-illust {
  position: absolute;
  right: 7.5%;
  top: 50%;
  transform: translateY(-50%);
}

@include media-breakpoint-down(sm) {
  .position-illust {
    right: 0 !important;
  }
}

@include media-breakpoint-down(md) {
  .position-illust {
    width: 100% !important;
    position: relative;
    text-align: center;
    right: 0 !important;
    top: 0;
    transform: translateY(0%);
  }
}

@include media-breakpoint-down(lg) {
  .position-illust {
    right: 3.75%;
  }
}

@include media-breakpoint-up(md) {
  .hero-img {
    max-width: 400%;
    width: 400%;
    left: -25%;
  }
}

@include media-breakpoint-up(lg) {
  .hero-img {
    max-width: 180%;
    width: 180%;
    left: 15%;
  }
}

@include media-breakpoint-up(xl) {
  .hero-img {
    max-width: 150%;
    width: 150%;
    left: 5%;
  }
}

@include media-breakpoint-up(xxl) {
  .hero-img {
    max-width: 130%;
    width: 130%;
    left: 5%;
  }
}

.parallax-block {
  min-height: 450px;
  background-size: cover;
  background-position: center center;
  background-color: #49b7f8;

  &:before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}

@supports not (-webkit-touch-callout: none) {
  .parallax-block {
    background-attachment: fixed;
  }
}

// only for Safari Mobile
@supports (-webkit-touch-callout: none) {
  .parallax-block {
    background-attachment: scroll;
  }
}

[data-overlay], [data-overlay-light] {
  position: relative;
}

[data-overlay]::before, [data-overlay-light]::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #191919;
  border-radius: inherit;
}

[data-overlay="5"]::before {
  opacity: .5;
}

.btn-white {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e9ecef;
  --bs-btn-active-border-color: var(--bs-btn-active-bg); // equal to --bs-btn-active-bg
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-red {
  --bs-btn-color: #fff;
  --bs-btn-bg: #d12335;
  --bs-btn-border-color: #d12335;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d12335;
  --bs-btn-hover-border-color: #d12335;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #d12335;
  --bs-btn-active-border-color: var(--bs-btn-active-bg); // equal to --bs-btn-active-bg
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #b52016;
  --bs-btn-disabled-border-color: #b52016;
  --bs-gradient: none;
}

.text-red {
  color: #d12335;
}
